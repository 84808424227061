<script src="//unpkg.com/v-viewer/dist/v-viewer.js"></script>

<template>
  <div class="component-tablebox">
    <!-- toolbar -->
    <b-row v-if="showToolbar" class="table-top-wrapper mb-2 mx-0">
      <b-col sm class="px-0">
        <b-form inline @submit.prevent>
          <b-input-group v-if="showSearch" data-v-step="0" class="box-shadow2 ml-2 mb-2">
            <b-form-input
              :class="{
                'bg-light text-dark': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              v-model="searchTerm"
              @keydown.native="search"
              placeholder="Search..."
            ></b-form-input>
            <template #append v-if="searchItems">
              <b-dropdown
                :menu-class="isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2'"
                :variant="isDarkMode ? 'primary' : 'success'"
                :text="selectedSearchVariable.text"
              >
                <b-dropdown-item
                  v-for="(item, index) in searchItems"
                  :key="index"
                  @click="
                    selectedSearchVariable = item;
                    searchTerm = '';
                  "
                >
                  {{ item.text }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-input-group>
          <div v-if="showFilter" data-v-step="1">
            <b-form-select
              v-model="filterSelected"
              :options="filterOptions"
              :class="{
                'box-shadow2 ml-2 mb-2 bg-light text-dark': !isDarkMode,
                'box-shadow2 ml-2 mb-2 bg-dark text-light': isDarkMode
              }"
            ></b-form-select>
          </div>
        </b-form>
      </b-col>
      <b-col sm class="px-0">
        <b-form data-v-step="2" inline class="float-right">
          <b-input-group class="box-shadow2 ml-2 mb-2">
            <b-input-group-append>
              <b-form-datepicker
                button-only
                id="start-datepicker"
                placeholder="Start Date"
                :min="minDate"
                :max="maxDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="startDateValue"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-form-input
              id="start-date-input"
              v-model="startDateValue"
              type="text"
              placeholder="Start Date"
              autocomplete="off"
              :class="{
                '': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              disabled
            ></b-form-input>
          </b-input-group>
          <b-input-group class="box-shadow2 ml-2 mb-2">
            <b-input-group-append>
              <b-form-datepicker
                button-only
                id="end-datepicker"
                placeholder="End Date"
                :min="minDate"
                :max="maxDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="endDateValue"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-form-input
              id="end-date-input"
              v-model="endDateValue"
              type="text"
              placeholder="End Date"
              autocomplete="off"
              :class="{
                '': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              disabled
            ></b-form-input>
          </b-input-group>
          <b-button
            :class="{
              'box-shadow2 btn btn-success ml-2 mb-2': !isDarkMode,
              'btn btn-primary text-light ml-2 mb-2': isDarkMode
            }"
            @click="applyDate"
            >Apply</b-button
          >
          <b-button
            :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
            class="box-shadow2 ml-2 mb-2"
            @click="clearDate"
            >Clear</b-button
          >
        </b-form>
      </b-col>
    </b-row>

    <!-- table button -->
    <b-row class="table-bottom-wrapper mb-2 mx-0">
      <b-col :cols="12" class="px-0">
        <b-button data-v-step="3" v-if="showAdd" size="sm" :class="buttonClasses" @click="clickAdd">
          <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />{{ addText }}
        </b-button>
        <b-button
          data-v-step="4"
          v-if="showForm && ifAdmin()"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light mb-2 mr-2': isDarkMode
          }"
          @click="clickOpenForm"
        >
          <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />{{ formButtonText }}
        </b-button>

        <b-button
          data-v-step="4"
          v-if="showDownload"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light mb-2 mr-2': isDarkMode
          }"
          @click="clickDownload"
        >
          <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadText }}
        </b-button>
        <b-button
          data-v-step="4"
          v-if="showDeletedDownload && !ifAdmin()"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light mb-2 mr-2': isDarkMode
          }"
          @click="clickDownloadDeleteList"
        >
          <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadDeleteText }}
        </b-button>

        <b-button
          data-v-step="5"
          v-if="showUpload"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light mb-2 mr-2': isDarkMode
          }"
          @click="clickUpload"
        >
          <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />{{ uploadText }}
        </b-button>
        <b-button
          v-if="showList"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light mb-2 mr-2': isDarkMode
          }"
          @click="clickList"
        >
          <font-awesome-icon :icon="['fas', 'eye']" class="mr-1" />{{ listText }}
        </b-button>
      </b-col>
    </b-row>
    <div class="table-top-wrapper">
      <div v-if="totalCount" class="row-total-count">
        <span>Total Count:</span>
        {{ totalCount }}
      </div>
    </div>

    <div
      :class="{
        'box-shadow2': !isDarkMode,
        'min-height-522': isDarkMode
      }"
    >
      <div
        :class="{
          'table-row table-row-header v-step-1': !isDarkMode,
          'table-row-dark v-step-1 dark-div border-top': isDarkMode
        }"
      >
        <div
          v-for="(column, index) in columns"
          :key="column.id"
          :class="{
            ...column.class,
            'table-column': !isDarkMode,
            'table-column-dark': isDarkMode,
            'table-column-first': index === 0,
            'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
          }"
          :style="columnStyle(column)"
        >
          {{ column.headerText }}
        </div>
      </div>
      <div
        :class="{
          'table-row ': !isDarkMode,
          'table-row-dark': isDarkMode
        }"
        v-for="row in rows"
        :key="row.id"
      >
        <div
          v-for="(column, index) in columns"
          :key="column.id"
          :class="{
            ...column.class,
            'table-column ': !isDarkMode,
            'table-column-dark': isDarkMode,
            'table-column-first': index === 0,
            'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
          }"
          :style="columnStyle(column)"
        >
          <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
          <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
          <div v-if="column.urlKey"
            ><a :href="row[column.urlKey]" target="_blank">{{ row[column.urlKey] }}</a></div
          >
          <span v-if="column.textKey && column.textKey !== 'images'" class="span-text">{{ row[column.textKey] }}</span>
          <div
            v-viewer
            v-if="column.type === 'image' && typeof row[column.textKey] !== 'string' && row[column.textKey]"
          >
            <div class="image-container">
              <template>
                <!-- Show first image -->
                <div v-if="row[column.textKey].length > 0" class="imageView">
                  <img
                    :src="row[column.textKey][0]"
                    class="dynamic-image box-shadow2"
                    thumbnail
                    fluid
                    alt="No Image Available"
                  />
                </div>
                <!-- Show more images indicator -->
                <div v-if="row[column.textKey].length > 1" class="more-images">
                  +{{ row[column.textKey].length - 1 }} more
                </div>
              </template>
            </div>
          </div>
          <link href="//unpkg.com/viewerjs/dist/viewer.css" rel="stylesheet" />

          <!-- <span v-if="column.textKey" class="span-text">{{ row[column.textKey] }}</span> -->
          <div v-if="column.type === 'functions'" class="text-center">
            <b-button
              data-v-step="7"
              size="sm"
              :variant="isDarkMode ? 'outline-light' : 'secondary'"
              @click="clickEdit(row)"
              class="button-fixed-width"
            >
              <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />
              <span>View/Edit</span>
            </b-button>
            <b-button
              v-if="!ifAdmin() && row.currentStatus !== 'IN PROGRESS' && row.currentStatus !== 'CLOSED'"
              size="sm"
              :variant="isDarkMode ? 'outline-warning' : 'warning'"
              @click="clickDelete(row)"
              class="mb-2 button-fixed-width"
              :class="{
                'box-shadow2': !isDarkMode,
                'text-white': isDarkMode
              }"
            >
              <font-awesome-icon :icon="['fas', 'trash-alt']" class="mr-1" />
              <span>Delete</span>
            </b-button>
          </div>
        </div>
      </div>
      <div
        v-if="!loading && rows.length === 0"
        :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }"
      >
        <div
          :class="{
            'table-column table-column-full text-center': !isDarkMode,
            'table-column-dark table-column-full text-center': isDarkMode
          }"
          >{{ emptyText }}</div
        >
      </div>
      <b-overlay v-if="loading" show :variant="isDarkMode ? 'outline-light' : 'outline-secondary'">
        <div :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }">
          <div
            :class="{
              'table-column table-column-full text-center': !isDarkMode,
              'table-column-dark table-column-full text-center': isDarkMode
            }"
          >
          </div>
        </div>
      </b-overlay>
    </div>

    <b-row class="table-bottom-wrapper mt-2 mx-0">
      <b-col lg="8" sm="12">
        <b-button
          v-if="showAdd"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light mb-2 mr-2': isDarkMode
          }"
          @click="clickAdd"
        >
          <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />{{ addText }}
        </b-button>
        <b-button
          v-if="showUpload"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light mb-2 mr-2': isDarkMode
          }"
          @click="clickUpload"
        >
          <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />{{ uploadText }}
        </b-button>
        <b-button
          v-if="showList"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light mb-2 mr-2': isDarkMode
          }"
          @click="clickList"
        >
          <font-awesome-icon :icon="['fas', 'eye']" class="mr-1" />{{ listText }}
        </b-button>
      </b-col>
      <b-col lg="4" sm="12" v-if="showPagination">
        <b-pagination-nav
          data-v-step="6"
          v-if="rows.length"
          v-model="currentPage"
          :link-gen="linkGen"
          :number-of-pages="totalNumberOfPage"
          use-router
          no-page-detect
          align="right"
          :class="{ '': !isDarkMode, 'dark-nav': isDarkMode }"
        ></b-pagination-nav>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import VueViewer from 'v-viewer';
import Vue from 'vue';

Vue.use(VueViewer);
export default {
  props: {
    columns: Array,
    rows: Array,
    pagination: Object,
    baseUrl: String,
    emptyText: String,
    formButtonText: String,
    emailButtonText: String,
    downloadText: String,
    downloadDeleteText: String,
    showAdd: Boolean,
    showList: Boolean,
    showDownload: Boolean,
    showDeletedDownload: Boolean,
    showForm: Boolean,
    showToolbar: Boolean,
    showSearch: Boolean,
    showFilter: Boolean,
    showUpload: Boolean,
    searchItems: Array,
    filterOptions: Array,
    steps: Array,
    callbacks: Object,
    downloadText: String,
    downloadDeleteText: String,
    uploadText: String,
    addText: String,
    listText: String,
    loading: Boolean,
    showPagination: Boolean
  },
  name: 'TransactionBox',
  data() {
    return {
      filterSelected: null,
      startDateValue: null,
      endDateValue: null,
      type: null,
      searchTerm: null,
      minDate: moment('2020-01-01').format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),
      selectedSearchVariable: this.searchItems && this.searchItems[0]
    };
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'filter')) {
      this.filterSelected = this.$route.query.filter;
    } else {
      this.filterSelected = null;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
      this.searchTerm = this.$route.query.search;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'searchkey')) {
      this.selectedSearchVariable = this.$route.query.searchkey;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'type')) {
      this.type = this.$route.query.type;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
      this.startDateValue = this.$route.query.startdate;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'enddate')) {
      this.endDateValue = this.$route.query.enddate;
    }
  },
  watch: {
    filterSelected(newValue, oldValue) {
      if (newValue !== oldValue && this.$route.query.filter !== newValue) {
        if (!newValue) {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        } else {
          this.$router.push({ query: { ...this.$route.query, filter: newValue } });
        }
      }
    },
    searchTerm(newValue) {
      if (newValue === '' && Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
        const query = { ...this.$route.query };
        delete query.search;
        this.$router.push({ query });
      }
    }
  },
  computed: {
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('auth', ['user']),
    currentPage() {
      if (this.pagination.page) {
        return this.pagination.page;
      }
      return 1;
    },
    totalNumberOfPage() {
      if (this.pagination) {
        if (this.pagination.total_rows && this.pagination.page_size) {
          return Math.ceil(this.pagination.total_rows / this.pagination.page_size);
        }
      }
      return 0;
    },
    totalCount() {
      if (this.pagination.total_rows) {
        return this.pagination.total_rows;
      }
      return 0;
    }
  },
  methods: {
    ifAdmin() {
      const isAdmin = this.user.roles.filter(role => role.name === 'FINANCE_ADMIN' || role.name === 'ADMIN');
      if (isAdmin.length) {
        return false;
      }
      return true;
    },
    search(event) {
      if (event.key === 'Enter') {
        if (this.searchTerm) {
          this.$router.push({
            query: {
              ...this.$route.query,
              search: this.searchTerm,
              searchkey: this.selectedSearchVariable.value
            }
          });
        } else {
          const query = { ...this.$route.query };
          delete query.search;
          delete query.searchkey;
          this.$router.push({ query });
        }
      }
    },
    applyDate() {
      if (this.startDateValue) {
        this.$router.push({
          query: {
            ...this.$route.query,
            startdate: moment(this.startDateValue).format('YYYY-MM-DD')
          }
        });
      }
      if (this.endDateValue) {
        this.$router.push({
          query: {
            ...this.$route.query,
            enddate: moment(this.endDateValue).format('YYYY-MM-DD')
          }
        });
      }
    },
    clearDate() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        const query = { ...this.$route.query };
        delete query.startdate;
        delete query.enddate;
        this.$router.push({ query });
      }
      this.startDateValue = null;
      this.endDateValue = null;
    },
    columnStyle(column) {
      return { width: column.width };
    },
    linkGen(pageNum) {
      return { path: this.baseUrl, query: { ...this.$route.query, page: pageNum } };
    },
    clickAdd() {
      this.$emit('add', {});
    },
    clickDownload() {
      this.$emit('download', {});
    },
    clickDownloadDeleteList() {
      this.$emit('download-delete', {});
    },
    clickOpenForm() {
      this.$emit('form', {});
    },
    // clickOpenEmailList() {
    //   this.$emit('emailList', {});
    // },
    clickDelete(row) {
      this.$emit('delete', { row });
    },
    // clickOpen(row) {
    //   this.$emit('open', { row });
    // },
    clickEdit(row) {
      this.$emit('edit', { row });
    },
    clickUpload() {
      this.$emit('show-upload', {});
    }
  }
};
</script>
<style scoped>
.button-fixed-width {
  width: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.imageView {
  display: inline-flex;
  margin: 2px;
}

img {
  width: 80px !important;
  height: 80px !important;
  transition: transform 0.2s;
}
/* .image-container {
  padding: 5px;
} */

/* .thumbnail-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 3px;
  cursor: pointer;
  transition: transform 0.2s;
}

.thumbnail-image:hover {
  transform: scale(1.1);
} */

/* Add v-viewer custom styles */
/* ::v-deep .v-viewer {
  z-index: 10000;
} */
</style>
