<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <transactionBox :columns="columns" :rows="complainList" :baseUrl="baseUrl" :loading="loading"
      :searchItems="searchItems" :filterOptions="filterOptions" :showSearch="true" :showFilter="true"
      :showToolbar="true" :showPagination="true" :pagination="pagination" :showForm="true" :showDownload="true"
      :showDeletedDownload="true" @edit="onEdit" @delete="onDelete" @form="onOpenForm" @emailList="onOpenEmailList"
      @download="onDownload" @download-delete="onDownloadDeletedList" emptyText="No Data Found."
      formButtonText="Create Complain" downloadText="Download List" downloadDeleteText="Download Deleted List">
    </transactionBox>
  </div>
</template>
<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import router from '@/router';
import Vue from 'vue';
import transactionBox from '../../components/transactionBox.vue';
import { watch } from 'vue';

export default {
  components: { transactionBox },
  name: 'complainList',
  metaInfo() {
    return {
      title: 'Transaction complainList'
    };
  },
  data() {
    return {
      title: 'Transaction Complain',
      updateList: [],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'index',
          width: '2%'
        },
        {
          type: 'string',
          headerText: 'Store Name',
          class: { 'text-center': true },
          textKey: 'storeName',
          width: '13%'
        },
        {
          type: 'string',
          headerText: 'Store ID',
          class: { 'text-center': true },
          textKey: 'storeId',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Customer Name',
          class: { 'text-center': true },
          textKey: 'customerName',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Mobile',
          class: { 'text-center': true },
          textKey: 'customerPhone',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Transaction ID',
          class: { 'text-center': true },
          textKey: 'transactionId',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Transaction amount',
          class: { 'text-center': true },
          textKey: 'transactionAmount',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Transaction Date',
          class: { 'text-center': true },
          textKey: 'transactionDate',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Status',
          class: { 'text-center': true },
          textKey: 'currentStatus',
          width: '10%'
        },
        {
          type: 'image',
          headerText: 'Images',
          class: { 'text-center': true },
          textKey: 'images',
          width: '10%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '10%'
        }
      ],
      searchItems: [
        { value: 'name', text: 'Name' },
        { value: 'store_id', text: 'Store ID' },
        { value: 'store_name', text: 'Store Name' },
        { value: 'transaction_id', text: 'Transaction ID' },
        { value: 'transaction_date', text: 'Transaction Date' }
      ],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'CREATED', text: 'CREATED' },
        { value: 'CLOSED', text: 'CLOSED' },
        { value: 'IN PROGRESS', text: 'IN PROGRESS' }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.list({
        query: {
          ...this.$route.query,
          startdate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.list({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.list({ query: to.query });
    next();
  },
  methods: {
    ...mapActions('transactionComplain', ['list', 'deleteOne', 'getImageUrl', 'deletedList', 'download']),
    onEdit({ row }) {
      this.updateList = row;
      router.push(`/customer-transaction-complaint/${row.id}`);
    },

    onDownload() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({
          query: {
            page: 1,
            page_size: 99999,
            ...this.$route.query
          }
        });
      } else {
        this.download({
          query: {
            page: 1,
            page_size: 99999,
            startdate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    },

    onDownloadDeletedList() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.deletedList({
          query: {
            page: 1,
            page_size: 99999,
            ...this.$route.query
          }
        });
      } else {
        this.deletedList({
          query: {
            page: 1,
            page_size: 99999,
            startdate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    },

    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    },
    onOpenForm() {
      this.$router.push('/transactioncomplain/new');
    },
    onOpenEmailList() {
      this.$router.push('/transactioncomplain/Email_List');
    }
  },
  computed: {
    ...mapState('transactionComplain', [
      'loading',
      'baseUrl',
      'pagination',
      'complainList',
      'imageUrl',
      'deletedComplainList',
      'downloadList'
    ]),
    ...mapState('darkMode', ['isDarkMode'])
  },
  watch: {
    downloadList(newValue) {
      if (newValue.length > 0) {
        let csv =
          'Row Number,Store Name,Store ID,Customer Name,Customer Mobile,Transaction ID,Complaint Number,Transaction Type,Transaction amount,Transaction Date,Status,Customer Remarks,In Progress Remarks,Approver Remarks\n';
        newValue.forEach((row, rowIndex) => {
          csv += `${rowIndex + 1},${row['storeName'] || ''},${row['storeId'] || ''},${row['customerName'] || ''},${row['customerPhone'] || ''
            },${row['transactionId'] || ''},${row['complaintNumber'] || ''},${row['transactionType'] || ''},${row['transactionAmount'] || ''
            },${row['transactionDate'] || ''},${row['currentStatus'] || ''},${row['customerRemarks'] ? row['customerRemarks'].replace(/[\n\r\t\f\v,]/g, ' ') : ''
            },${row['inProgressRemarks'] ? row['inProgressRemarks'].replace(/[\n\r\t\f\v,]/g, ' ') : ''},${row['approverRemarks'] ? row['approverRemarks'].replace(/[\n\r\t\f\v,]/g, ' ') : ''
            }\n`;
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Transaction_List.csv';
        anchor.click();
      }
    },
    deletedComplainList(newValue) {
      if (newValue.length > 0) {
        let csv =
          'Row Number,Store Name,Store ID,Customer Name,Customer Mobile,Transaction ID,Complaint Number,Transaction Type,Transaction Amount,Transaction Date,Status,Deleted By,Customer Remarks,In Progress Remarks,Approver Remarks\n';

        newValue.forEach((row, rowIndex) => {
          csv += `${rowIndex + 1},${row['storeName'] || ''},${row['storeId'] || ''},${row['customerName'] || ''},${row['customerPhone'] || ''
            },${row['transactionId'] || ''},${row['complaintNumber'] || ''},${row['transactionType'] || ''},${row['transactionAmount'] || ''
            },${row['transactionDate'] || ''},${row['currentStatus'] || ''},${row['archivedByName'] || ''},${row['customerRemarks'] ? row['customerRemarks'].replace(/[\n,]/g, ' ') : ''
            },${row['inProgressRemarks'] ? row['inProgressRemarks'].replace(/[\n,]/g, ' ') : ''},${row['approverRemarks'] ? row['approverRemarks'].replace(/[\n,]/g, ' ') : ''
            }\n`;
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Deleted_Transaction_List.csv';
        anchor.click();
      }
    }
  }
};
</script>
